var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-wrapper mt-5 mb-5"},[_c('div',{staticClass:"bg-gray-100 p-3 rounded"},[_c('CForm',{staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();return _vm.handleAddUser($event)}}},[_c('div',{staticClass:"flex-fill mr-3"},[_c('CInput',{attrs:{"label":"Add customer","placeholder":"Add by customer ID"},model:{value:(_vm.formData.user),callback:function ($$v) {_vm.$set(_vm.formData, "user", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.user"}})],1),_c('div',[_c('CButton',{staticClass:"button-add",attrs:{"disabled":_vm.users.isSubmitting || !_vm.formData.user,"type":"submit","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.users.isSubmitting ? 'Adding' : 'Add')+" ")])],1)])],1)]),_c('div',{staticClass:"px-4"},[_c('CRow',[_c('CCol',{attrs:{"md":"8"}},[_c('form',{staticClass:"search-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSearch($event)}}},[_c('CInput',{attrs:{"data-test-id":"search-input","placeholder":"Search by username, name, store name, store ID"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-magnifying-glass","data-test-id":"search-button"}})]},proxy:true}]),model:{value:(_vm.queryParams.q),callback:function ($$v) {_vm.$set(_vm.queryParams, "q", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryParams.q"}})],1)]),_c('CCol',{attrs:{"md":"4"}},[_c('BaseDropDown',{attrs:{"options":_vm.CUSTOMER_STATUS_OPTIONS,"label":"name"},on:{"input":_vm.handleSearch},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('CRow',[_c('CCol',[_c('BaseTable',{staticClass:"table-custom",attrs:{"fields":_vm.CUSTOMER_LIST_FIELDS,"items":_vm.dataTable,"is-loading":_vm.users.isLoading,"pagination":{
						pages: _vm.users.meta.lastPage,
						activePage: _vm.users.meta.currentPage
					},"striped":""},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("global.searchNotFound", { field: "users" }))+" ")])])]},proxy:true},{key:"status",fn:function(ref){
					var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"delete",fn:function(ref){
					var item = ref.item;
return [_c('CButton',{attrs:{"variant":"ghost"},on:{"click":function($event){return _vm.showConfirmDialog(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)]}}])})],1)],1)],1),_c('BaseModalConfirmDelete',{ref:"modal-confirm-delete-user",attrs:{"handle-remove":_vm.handleRemoveUserFromGroup.bind(null, _vm.$route.params.id, _vm.selectedUser),"title":"Remove this user from the group?","description":"By removing this, user will be assigned to 'General' customer group"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }