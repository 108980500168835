<template>
	<div class="tabs-container">
		<div class="tabs-wrapper">
			<CTabs
				:active-tab="activeTab"
				@update:activeTab="handleActiveTab"
			>
				<CTab title="General info">
					<BaseLoading v-if="edit.isLoading" />
					<FormCustomerGroup
						v-else
						:is-submitting="edit.isUpdating"
						:name="edit.data.name"
						:description="edit.data.description"
						:rank="edit.data.rank"
						:is-deletable="edit.data.isDeletable"
						is-edit
						@onConfirm="handleConfirm"
						@onRemove="$refs['modal-confirm-delete-group'].open()"
					/>
				</CTab>
				<CTab title="Customer list">
					<FormCustomerGroupUserList />
				</CTab>
			</CTabs>
		</div>
		<BaseModalConfirmDelete
			ref="modal-confirm-delete-group"
			:handle-remove="handleDeleteCustomerGroup.bind(null, $route.params.id)"
			title="Remove this group?"
			description="By removing this, all users will be assigned to general group"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormCustomerGroup from '@/components/FormCustomerGroup.vue';
import FormCustomerGroupUserList from '@/components/FormCustomerGroupUserList.vue';

export default {
	name: 'CustomerGroupEdit',
	components: {
		FormCustomerGroup,
		FormCustomerGroupUserList,
	},
	data() {
		const activeTab = this.$route.query.activeTab
			? Number(this.$route.query.activeTab)
			: 0;
		return {
			activeTab,
		};
	},
	computed: {
		...mapState('customerGroups', {
			edit: 'edit',
		}),
	},
	async created() {
		const id = this.$route.params.id;
		await this.getCustomerGroup(id);
	},
	methods: {
		...mapActions({
			getCustomerGroup: 'customerGroups/getCustomerGroup',
			updateCustomerGroup: 'customerGroups/updateCustomerGroup',
			deleteCustomerGroup: 'customerGroups/deleteCustomerGroup',
		}),
		handleActiveTab(activeTab) {
			this.$router.push({ query: { activeTab } });
		},
		async handleConfirm(params) {
			const id = this.$route.params.id;
			await this.updateCustomerGroup({ id, params });
		},
		async handleDeleteCustomerGroup(id) {
			await this.deleteCustomerGroup(id);
		},
	},
};
</script>
