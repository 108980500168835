<template>
	<div>
		<div class="main-wrapper mt-5 mb-5">
			<div class="bg-gray-100 p-3 rounded">
				<CForm
					class="d-flex"
					@submit.prevent="handleAddUser"
				>
					<div class="flex-fill mr-3">
						<CInput
							v-model.trim="formData.user"
							label="Add customer"
							placeholder="Add by customer ID"
						/>
					</div>
					<div>
						<CButton
							:disabled="users.isSubmitting || !formData.user"
							type="submit"
							color="secondary"
							class="button-add"
						>
							{{ users.isSubmitting ? 'Adding' : 'Add' }}
						</CButton>
					</div>
				</CForm>
			</div>
		</div>
		<div class="px-4">
			<CRow>
				<CCol md="8">
					<form
						class="search-form"
						@submit.prevent="handleSearch"
					>
						<CInput
							v-model.trim="queryParams.q"
							data-test-id="search-input"
							placeholder="Search by username, name, store name, store ID"
						>
							<template #prepend-content>
								<CIcon name="cil-magnifying-glass" data-test-id="search-button" />
							</template>
						</CInput>
					</form>
				</CCol>
				<CCol md="4">
					<BaseDropDown
						v-model="status"
						:options="CUSTOMER_STATUS_OPTIONS"
						label="name"
						@input="handleSearch"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<BaseTable
						:fields="CUSTOMER_LIST_FIELDS"
						:items="dataTable"
						:is-loading="users.isLoading"
						:pagination="{
							pages: users.meta.lastPage,
							activePage: users.meta.currentPage
						}"
						striped
						class="table-custom"
						@onPaginationClick="handlePageChange"
					>
						<template #no-items-view>
							<div class="empty-table-element">
								<p class="subtitle">
									{{ $t("global.searchNotFound", { field: "users" }) }}
								</p>
							</div>
						</template>
						<template #status="{ item }">
							<CBadge
								:color="item.color"
								class="badge-status"
							>
								{{ item.title }}
							</CBadge>
						</template>
						<template #delete="{ item }">
							<CButton
								variant="ghost"
								@click="showConfirmDialog(item)"
							>
								<CIcon name="cil-trash" />
							</CButton>
						</template>
					</BaseTable>
				</CCol>
			</CRow>
		</div>
		<BaseModalConfirmDelete
			ref="modal-confirm-delete-user"
			:handle-remove="handleRemoveUserFromGroup.bind(null, $route.params.id, selectedUser)"
			title="Remove this user from the group?"
			description="By removing this, user will be assigned to 'General' customer group"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CUSTOMER_STATUS_COLOR, CUSTOMER_STATUS_OPTIONS } from '../enums/customers';
import { capitalize, cleanObject, cloneDeep } from '../assets/js/helpers';

export default {
	name: 'FormUserRoleUserList',
	data() {
		return {
			CUSTOMER_STATUS_COLOR,
			CUSTOMER_STATUS_OPTIONS,
			selectedUser: null,
			queryParams: {
				q: this.$route.query.q || null,
				customer_status: this.$route.query.customer_status || null,
				page: Number(this.$route.query.page) || null,
			},
			formData: {
				user: null,
			},
		};
	},
	computed: {
		...mapState('customerGroups', {
			edit: 'edit',
			users: 'users',
		}),
		CUSTOMER_LIST_FIELDS() {
			return [
				{ key: 'id', label: 'ID' },
				{ key: 'fullname', label: 'Name' },
				{ key: 'email', label: 'Email' },
				{ key: 'phoneNumber', label: 'Phone no.' },
				{ key: 'status', label: 'Status' },
				{ key: 'delete', label: '' },
			];
		},
		dataTable() {
			return this.users.data.map((user) => {
				return {
					id: user.id,
					fullname: user.fullname,
					email: user.email,
					phoneNumber: user.phoneNumber,
					delete: user.id,
					status: {
						color: CUSTOMER_STATUS_COLOR[user.customerStatus],
						title: capitalize(user.customerStatus),
					},
				};
			});
		},
		status: {
			get() {
				return CUSTOMER_STATUS_OPTIONS.find((option) => option.value === this.queryParams.customer_status);
			},
			set(status) {
				this.queryParams.customer_status = status?.value;
				this.updateUrlParams();
			},
		},
	},
	async created() {
		const customerGroupId = this.$route.params.id;
		this.getCustomerGroupUsers({
			id: customerGroupId,
			query: this.queryParams,
		});
	},
	methods: {
		...mapActions({
			getCustomerGroupUsers: 'customerGroups/getCustomerGroupUsers',
			addUserToGroup: 'customerGroups/addUserToGroup',
			removeUserFromGroup: 'customerGroups/removeUserFromGroup',
		}),
		updateUrlParams() {
			const activeTab = this.$route.query.activeTab;
			const query = cleanObject({ ...cloneDeep(this.queryParams), activeTab });
			this.$router.push({ query }).catch(() => {});
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		async showConfirmDialog(item) {
			this.selectedUser = item;
			this.$refs['modal-confirm-delete-user'].open();
		},
		async handleAddUser() {
			const customerGroupId = this.$route.params.id;
			const user = this.formData.user;
			await this.addUserToGroup({ customerGroupId, user });
			this.formData.user = '';
		},
		async handleRemoveUserFromGroup(customerGroupId, userId) {
			await this.removeUserFromGroup({ customerGroupId, userId });
		},
	},
};
</script>
<style lang="scss" scoped>
	.button-add {
		margin-top: rem(32);
	}
</style>
